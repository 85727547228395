export enum ScreensEnum {
  RTC_TO_SCREEN = 'rtc_to_screen',
  SCREEN_TO_RTC = 'screen_to_rtc',
  GET_SCREEN_SIZE = 'get_screen_size',
  // GET_SCREEN_SIZE_REPLY = 'get_screen_size_reply',
  CREATE_AR_SCREEN = 'create_ar_screen',
  DESTROY_AR_SCREEN = 'destroy_ar_screen',

  TO_IPC = 'to_ipc',
  IPC_REPLY = 'ipc_reply',
  IPC_REPLY_AR = 'ipc_reply_ar'
}
// MESSAGES BETWEEN SCREEN AND RTC
export enum RTCEvents {
  RTC_SCREENSHOT = 'rtc_screenshot',
  RTC_SCREENSIZE = 'rtc_screensize',
  RTC_POSITION = 'rtc_position',
  RTC_TEXT = 'rtc_text',
  RTC_CLEAR_TEXT = 'rtc_clear_text',
  RTC_IMAGE = 'rtc_image',
  RTC_CLEAR_IMAGE = 'rtc_clear_image',
  RTC_SHOW = 'rtc_show',
  RTC_HIDE = 'rtc_hide',
  RTC_PASTE = 'rtc_paste',
  RTC_PASTE_TEXT = 'rtc_paste_text',
  RTC_PASTE_IMAGE = 'rtc_paste_image',
  START_AR_SCREEN = 'start_ar_screen',
  END_AR_SCREEN = 'end_ar_screen',
  RTC_SHOW_TRACKING_IMAGE = 'rtc_show_tracking_image',
  RTC_CLIENT_CONNECTED = 'rtc_client_connected',
  RTC_DISCONNECT_CLIENT = 'rtc_disconnect_client'

}

export enum RTCEnum {
  USER_LINK_ID = 'userLinkID',
}

export default {
  ScreensEnum,
  RTCEvents,
};
