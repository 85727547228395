import React from 'react';
import Cubes from './cubes';

interface Props {
  msg?: string;
}

const CubesLoader = ({ msg }: Props) => {
  const data = {
    size: 200,
  };

  return (
    <div
      style={{
        margin: 'auto',
      }}
    >
      <Cubes data={data} />
      {msg ? (
        <span
          style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '30px',marginTop: '30px' }}
        >
          {msg}
        </span>
      ) : (
        <span
          style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '55px',marginTop: '30px'  }}
        >
          Loading ...
        </span>
      )}
    </div>
  );
};

CubesLoader.defaultProps = {
  msg: undefined,
};

export default CubesLoader;
