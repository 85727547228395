import React from 'react';

interface LayoutManagerContextProps {
  sideBarOpen: boolean;
  setSideBarOpen: (val: boolean) => void;
}
const LayoutManagerContext: React.Context<LayoutManagerContextProps> = React.createContext(
  {} as LayoutManagerContextProps
);
export default LayoutManagerContext;
