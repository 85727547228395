var firebaseConfig = {
  apiKey: "AIzaSyDF7JLOSa1vcpM72SLobwuQYaiaVXWuQc8",
  authDomain: "etime-dd617.firebaseapp.com",
  databaseURL: "https://etime-dd617.firebaseio.com",
  projectId: "etime-dd617",
  storageBucket: "etime-dd617.appspot.com",
  messagingSenderId: "355385846870",
  appId: "1:355385846870:web:373f1924dd76ba5cf09ff7",
  measurementId: "G-4723SHNL3L"
};
export default firebaseConfig;
