import { BehaviorSubject } from 'rxjs';
import { DataMsgFormat } from '../types/event.types';

// Behavior events
export const AR_TO_RTC_SUB = new BehaviorSubject<DataMsgFormat | null>(null);
export const IPC_TO_SCREEN_SUB = new BehaviorSubject<DataMsgFormat | null>(
  null
);

class EventService {
  constructor() {
    this.init();
  }

  init = () => {
    // ipcReply();
  };
}
export default EventService;
