import React from 'react';
import DBService from '../services/db.service';
import FirebaseService from '../services/firebase.service';
import RtcService from '../services/rtc.service';
import EventService from '../services/event.service';
import { DBSettings } from '../types/db/settings.types';
import { SettingsContextType } from '../types/settings.context.types';

// Set up services
export const db = new DBService();
export const events = new EventService();
export const fire = new FirebaseService();
export const rtc = new RtcService(fire.app);

export const DBContext: React.Context<DBService> = React.createContext(db);
export const FireContext: React.Context<FirebaseService> = React.createContext(
  fire
);

export const RtcContext: React.Context<RtcService> = React.createContext(rtc);

export const SettingsContext: React.Context<SettingsContextType> = React.createContext(
  {} as SettingsContextType
);

/**
 * Initiates the context mangement system
 * @param cb Takes a callback that sets the link id within reacts context
 */
export const initContextManager = async (
  setSettings: (settings: DBSettings) => void
) => {
  try {
    // init the db service
    // await db.init();
    return setSettings({});
  } catch (error) {
    console.log('err intializing context ', error);
  }
  return true;
};
