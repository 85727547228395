import { RxJsonSchema } from "rxdb";
import { DBSettings } from "../../types/db/settings.types";

const dbSettingsSchema: RxJsonSchema<DBSettings> = {
    title: 'settings schema',
    version: 0,
    description: 'describes settings table',
    keyCompression: true,
    type: 'object',
    properties: {
      id: {
        type: 'string',
        primary: true,
      },
      user: {
        type: 'string',
      },
      user_link_id: {
        type: 'string',
      },
    },
    required: ['id'],
  };

export default dbSettingsSchema; 

/**
 * 
 * FOR GENERATING TYPESCRIPT TO JSON 
 * 
 * ./node_modules/.bin/ts-json-schema-generator \
--tsconfig 'tsconfig.json' \
--expose none \
--no-top-ref \
--jsDoc basic \
--path 'src/types/db/schedule.types.ts' \
--type 'ScheduleType' \
--additional-properties false \
--out 'src/constants/schemas/schedule1.json'
 */