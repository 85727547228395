import React from 'react';
import AppLayout from '../components/layout';
import LayoutManagerContext from '../contexts/layout.context';

type Props = {
  children: React.ReactNode;
};

const LayoutManager = ({ children }: Props) => {
  const [sideBarOpen, setSideBarOpen] = React.useState(false);
  return (
    <LayoutManagerContext.Provider value={{ sideBarOpen, setSideBarOpen }}>
      <AppLayout>{children}</AppLayout>
    </LayoutManagerContext.Provider>
  );
};
export default LayoutManager;
