import { Dashboard } from '@styled-icons/boxicons-solid';
import { Settings } from '@styled-icons/ionicons-sharp/';
import { MenuFold, MenuUnfold } from '@styled-icons/remix-fill';
import { Layout, Menu, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../constants/routes.json';
import LayoutManagerContext from '../../contexts/layout.context';


const { Header, Sider, Content } = Layout;

type Props = {
  children: React.ReactNode;
};
const ICON_SIZE_SIDEBAR = 20;
const ICON_SIZE_MENU = 18;
const AppLayout = ({ children }: Props) => {
  const [currentPage, setCurrentPage] = useState<string>();
  const { setSideBarOpen, sideBarOpen } = useContext(LayoutManagerContext);
  const history = useHistory();
  // const path = useLocation().pathname;
  const path = '/';
  const handleChangeMenu = (e: any) => {
    switch (e.key) {
      case 'Dashboard':
        history.push(routes.DASHBOARD);
        break;
      case 'Settings':
        history.push(routes.SETTINGS);
        break;
      default:
        break;
    }
  };
  const getPathName = (): string => {
    const pathName = 'Dashboard'; // path === '/' ? 'Dashboard' : path.split('/')[1];
    const pathNameCapitalized =
      pathName.charAt(0).toUpperCase() + pathName.slice(1);
    setCurrentPage(pathNameCapitalized);
    return pathNameCapitalized;
  };
  useEffect(() => {
    getPathName();
  });

  return (
    <AppTopLayout id='appLayout'>
      <AppSider
        id='appSider'
        trigger={null}
        collapsible
        collapsed={sideBarOpen}
      >
        {sideBarOpen ? (
          <SideBarLogoTitleShort level={4}>E</SideBarLogoTitleShort>
        ) : (
          <SideBarLogoTitleLong level={4}>Etime</SideBarLogoTitleLong>
        )}
        {currentPage && (
          <SiderMenu
            id='appSiderMenu'
            theme='dark'
            mode='inline'
            defaultSelectedKeys={[currentPage]}
            onSelect={handleChangeMenu}
          >
            <Menu.Item
              key='Dashboard'
               title="Dashboard"
              icon={<DashboardIcon size={ICON_SIZE_MENU} />}
              children={!sideBarOpen?<MenuItemTitle>Dashboard</MenuItemTitle>:[]}
            />
            <Menu.Item key='Settings' title="Settings"
            icon={<SettingsIcon size={ICON_SIZE_MENU} />}
            children={!sideBarOpen?<MenuItemTitle>Settings</MenuItemTitle>:[]}
            />
          </SiderMenu>
        )}
      </AppSider>
      <AppMainLayout>
        <AppMainHeader>
          <HeaderSidebarIconContainer>
            {sideBarOpen ? (
              <MenuUnfoldIcon
                size={ICON_SIZE_SIDEBAR}
                onClick={() => setSideBarOpen(!sideBarOpen)}
              />
            ) : (
              <MenufoldIcon
                size={ICON_SIZE_MENU}
                onClick={() => setSideBarOpen(!sideBarOpen)}
              />
            )}
          </HeaderSidebarIconContainer>
          <PageHeaderTitle>
            <Typography.Title level={4}>{currentPage}</Typography.Title>
          </PageHeaderTitle>
        </AppMainHeader>
        <AppMainContent>{children}</AppMainContent>
      </AppMainLayout>
    </AppTopLayout>
  );
};
export default AppLayout;

const AppTopLayout = styled(Layout)`
  height: 100vh !important;
  background-color: #0ae !important;
`;
const AppSider = styled(Sider)``;

const SiderMenu = styled(Menu)``;

const MenuItemTitle = styled(Typography.Text)`
color:whitesmoke;
`
const SideBarLogoTitleLong = styled(Typography.Title)`
  color: whitesmoke;
  width: 9.7rem;
  text-align: center;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 5px;
  &.ant-typography {
    color: whitesmoke;
  }
`;
const SideBarLogoTitleShort = styled(Typography.Title)`
  font-size: 1.5rem;
  width: 3.7rem;
  text-align: center;
  margin-left: 5px;
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 10px;
  &.ant-typography {
    color: whitesmoke;
  }
`;

const AppMainLayout = styled(Layout)``;
const AppMainHeader = styled(Header)`
  background: #fff;
  display: flex;
  padding: 0;
`;
const HeaderSidebarIconContainer = styled.div`
  flex: 0.1;
`;
const DashboardIcon = styled(Dashboard)`
margin-right:5px;
margin-bottom: 2px;
`;
const SettingsIcon = styled(Settings)`
margin-right: 5px;
margin-bottom: 2px;
`;
const MenufoldIcon = styled(MenuFold)`
  margin-left: 10px;
  /* padding: 0 24px; */
  /* font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color:red;

  :hover {
    color: #1890ff;
  } */
`;
const MenuUnfoldIcon = styled(MenuUnfold)`
  margin-left: 10px;
  /* padding: 0 24px; */
  /* font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
  color:red;

  :hover {
    color: #1890ff;
  } */
`;
const SidebarLogoImg = styled.div`
  width: 3.7rem;
  text-align: center;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
`;
const SidebarLogoText = styled.div`
  width: 9.7rem;
  text-align: center;
  padding-left: 20px;
  padding-top: 15px;
  padding-bottom: 16px;
`;

const PageHeaderTitle = styled.div`
  flex: 1;
  align-self: center;
  margin-left: -15px;
  margin-bottom: -5px;
`;

const AppMainContent = styled(Content)`
  background: #fff;
  display: flex;
  margin: 24px 16px;
  padding: 24px;
  min-height: 280px;
`;
