/* eslint react/jsx-props-no-spreading: off */
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import routes from './constants/routes.json';
import App from './containers/App';
import Cubesloader from './components/loader';

export const history = createBrowserHistory();
// Lazily load routes and code split with webpacck
const LazyDashboardPage = React.lazy(() =>
  import('./containers/DashboardPage')
);

const LazySettingsPage = React.lazy(() => import('./containers/SettingsPage'));

const DashboardPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<Cubesloader.CubesLoader />}>
    <LazyDashboardPage {...props} />
  </React.Suspense>
);

const SettingsPage = (props: Record<string, any>) => (
  <React.Suspense fallback={<Cubesloader.CubesLoader />}>
    <LazySettingsPage {...props} />
  </React.Suspense>
);

export default function Routes() {
  return (
    <BrowserRouter>
      <App>
        <Switch>
          <Route exact path={routes.SETTINGS} component={SettingsPage} />
          <Route exact path={routes.DASHBOARD} component={DashboardPage} />
        </Switch>
      </App>
    </BrowserRouter>
  );
}
