import { RxJsonSchema } from "rxdb";
import { ScheduleType } from "../../types/db/schedule.types";

const scheduleSchema: RxJsonSchema<ScheduleType> = {
    "title": "schedule schema",
    "version": 0,
    "description": "describes a schedule",
    "type": "object",
    "properties": {
      "created": {
        "type": "string"
      },
      "name": {
        "type": "string"
      },
      "createdBy": {
        "type": "string"
      },
      "edits": {
        "items": {
          "properties": {
            "date": {
              "type": "string"
            },
            "editedBy": {
              "type": "string"
            },
            "id": {
              "type": "string"
            }
          },
          "required": [
            "id",
            "date",
            "editedBy"
          ],
          "type": "object"
        },
        "type": "array"
      },
      "id": {
        "type": "string",
        "primary": true,
      },
      "lastupdated": {
        "type": "string"
      },
      "schedule": {
        "properties": {
          "Contracts": {
            "items": {
              "properties": {
                "AlternativeSkillCategory": {
                  "properties": {
                    "Value": {
                      "type": "boolean"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "CompleteWeekends": {
                  "properties": {
                    "Value": {
                      "type": "boolean"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "Description": {
                  "type": "string"
                },
                "ID": {
                  "type": "string"
                },
                "IdenticalShiftTypesDuringWeekend": {
                  "properties": {
                    "Value": {
                      "type": "boolean"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MaxConsecutiveFreeDays": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MaxConsecutiveWorkingDays": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MaxConsecutiveWorkingWeekends": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MaxNumAssignments": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MaxWorkingWeekendsInFourWeeks": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MinConsecutiveFreeDays": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MinConsecutiveWorkingDays": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MinConsecutiveWorkingWeekends": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "MinNumAssignments": {
                  "properties": {
                    "On": {
                      "type": "number"
                    },
                    "Value": {
                      "type": "number"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "On",
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "NoNightShiftBeforeFreeWeekend": {
                  "properties": {
                    "Value": {
                      "type": "boolean"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "SingleAssignmentPerDay": {
                  "properties": {
                    "Value": {
                      "type": "boolean"
                    },
                    "Weight": {
                      "type": "number"
                    }
                  },
                  "required": [
                    "Weight",
                    "Value"
                  ],
                  "type": "object"
                },
                "UnwantedPatterns": {
                  "items": {
                    "type": "string"
                  },
                  "type": "array"
                },
                "WeekendDefinition": {
                  "type": "string"
                }
              },
              "required": [
                "ID",
                "Description",
                "SingleAssignmentPerDay",
                "MaxNumAssignments",
                "MinNumAssignments",
                "MaxConsecutiveWorkingDays",
                "MinConsecutiveWorkingDays",
                "MaxConsecutiveFreeDays",
                "MinConsecutiveFreeDays",
                "MaxConsecutiveWorkingWeekends",
                "MinConsecutiveWorkingWeekends",
                "MaxWorkingWeekendsInFourWeeks",
                "WeekendDefinition",
                "CompleteWeekends",
                "IdenticalShiftTypesDuringWeekend",
                "NoNightShiftBeforeFreeWeekend",
                "AlternativeSkillCategory",
                "UnwantedPatterns"
              ],
              "type": "object"
            },
            "type": "array"
          },
          "CoverRequirements": {
            "properties": {
              "Cover": {
                "items": {
                  "properties": {
                    "Preferred": {
                      "type": "number"
                    },
                    "Shift": {
                      "type": "string"
                    }
                  },
                  "required": [
                    "Shift",
                    "Preferred"
                  ],
                  "type": "object"
                },
                "type": "array"
              },
              "Day": {
                "enum": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday"
                ],
                "type": "string"
              },
              "Type": {
                "type": "string"
              }
            },
            "required": [
              "Day",
              "Type",
              "Cover"
            ],
            "type": "object"
          },
          "DayOffRequests": {
            "properties": {
              "Date": {
                "type": "string"
              },
              "EmployeeID": {
                "type": "string"
              },
              "HardWeight": {
                "type": "number"
              },
              "Weight": {
                "type": "number"
              }
            },
            "required": [
              "Weight",
              "HardWeight",
              "EmployeeID",
              "Date"
            ],
            "type": "object"
          },
          "Employees": {
            "items": {
              "properties": {
                "ContractID": {
                  "type": "string"
                },
                "HoursWorked": {
                  "items": {
                    "properties": {
                      "Hours": {
                        "type": "number"
                      },
                      "Month": {
                        "enum": [
                          1,
                          2,
                          3,
                          4,
                          5,
                          6,
                          7,
                          8,
                          9,
                          10,
                          11,
                          12
                        ],
                        "type": "number"
                      }
                    },
                    "required": [
                      "Month",
                      "Hours"
                    ],
                    "type": "object"
                  },
                  "type": "array"
                },
                "ID": {
                  "type": "string"
                },
                "Name": {
                  "type": "string"
                },
                "Skills": {
                  "items": {
                    "additionalProperties": false,
                    "type": "object"
                  },
                  "type": "array"
                }
              },
              "required": [
                "ID",
                "ContractID",
                "Name",
                "Skills",
                "HoursWorked"
              ],
              "type": "object"
            },
            "type": "array"
          },
          "EndDate": {
            "type": "string"
          },
          "HourDistribution": {
            "enum": [
              "1M",
              "10M",
              "15M",
              "30M",
              "60M"
            ],
            "type": "string"
          },
          "HoursAllowed": {
            "type": "number"
          },
          "ID": {
            "type": "string"
          },
          "Patterns": {
            "items": {
              "properties": {
                "ID": {
                  "type": "string"
                },
                "PatternEntries": {
                  "items": {
                    "properties": {
                      "Day": {
                        "anyOf": [
                          {
                            "enum": [
                              "Monday",
                              "Tuesday",
                              "Wednesday",
                              "Thursday",
                              "Friday",
                              "Saturday",
                              "Sunday"
                            ],
                            "type": "string"
                          },
                          {
                            "enum": [
                              "Any"
                            ],
                            "type": "string"
                          }
                        ]
                      },
                      "Index": {
                        "type": "number"
                      },
                      "ShiftType": {
                        "type": "string"
                      }
                    },
                    "required": [
                      "Index",
                      "ShiftType",
                      "Day"
                    ],
                    "type": "object"
                  },
                  "type": "array"
                },
                "Weight": {
                  "type": "number"
                }
              },
              "required": [
                "ID",
                "Weight",
                "PatternEntries"
              ],
              "type": "object"
            },
            "type": "array"
          },
          "SchedulingPeriodElement": {
            "type": "string"
          },
          "ShiftOffRequests": {
            "properties": {
              "Date": {
                "type": "string"
              },
              "EmployeeID": {
                "type": "string"
              },
              "ShiftTypeID": {
                "type": "string"
              },
              "Weight": {
                "type": "number"
              }
            },
            "required": [
              "Weight",
              "ShiftTypeID",
              "EmployeeID",
              "Date"
            ],
            "type": "object"
          },
          "ShiftTypes": {
            "items": {
              "properties": {
                "Description": {
                  "type": "string"
                },
                "EndTime": {
                  "type": "string"
                },
                "ID": {
                  "type": "string"
                },
                "Skills": {
                  "items": {
                    "additionalProperties": false,
                    "type": "object"
                  },
                  "type": "array"
                },
                "StartTime": {
                  "type": "string"
                }
              },
              "required": [
                "ID",
                "StartTime",
                "EndTime",
                "Description",
                "Skills"
              ],
              "type": "object"
            },
            "type": "array"
          },
          "Skills": {
            "items": {
              "additionalProperties": false,
              "type": "object"
            },
            "type": "array"
          },
          "StartDate": {
            "type": "string"
          }
        },
        "required": [
          "ID",
          "SchedulingPeriodElement",
          "StartDate",
          "EndDate",
          "Skills",
          "HoursAllowed",
          "HourDistribution",
          "ShiftTypes",
          "Patterns",
          "Contracts",
          "Employees",
          "CoverRequirements"
        ],
        "type": "object"
      }
    },
    "required": [
      "id",
      "schedule",
      "created",
      "createdBy"
    ],
  };

export default scheduleSchema;