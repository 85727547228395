import React, { ReactNode } from 'react';
import ContextManager from './ContextManager';
import LayoutManager from './LayoutManager';
import '../app.global.less';

type Props = {
  children: ReactNode;
};

const App = (props: Props) => {


  const { children } = props;
  return (
    <ContextManager>
      <LayoutManager>{children}</LayoutManager>
    </ContextManager>
  );
};
export default App;
