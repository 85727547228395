
import dbSettingsSchema from "./schemas/settings.schema";
import dbScheduleSchema from "./schemas/schedule.schema";

export enum DBCollectionEnum {
  DB_SCHEDULES = 'db_schedules',
  DB_SETTINGS = 'db_settings',
}

export default { DBCollectionEnum, dbSettingsSchema, dbScheduleSchema };
