import React from 'react';
import styles from './index.less';
// import logo from '../../../assets/logo.png';

const CubesLoaderSmall = () => {
  return (
    <div className={styles.container}>
      <div id="content">
        {/* <img className={styles.img} alt="" src={logo} /> */}
        <h1>Etime.io</h1>
      </div>
      <div className={styles.group}>
        <div className={styles.bigSqr}>
          <div className={`${styles.square} ${styles.first}`} />
          <div className={`${styles.square} ${styles.second}`} />
          <div className={`${styles.square} ${styles.third}`} />
          <div className={`${styles.square} ${styles.fourth}`} />
        </div>
        <div className={styles.text}>loading</div>
      </div>
    </div>
  );
};
export default CubesLoaderSmall;
