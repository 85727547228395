import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import firebaseConfig from './firebase.config';

export default class FirebaseService {
  app: firebase.app.App | undefined;

  user: firebase.auth.Auth | undefined;

  connectedToFirebase = false;

  constructor() {
    this.init();
  }

  /**
   * Initiates the firebase service
   */
  private init = () => {
    this.app = Firebase.initializeApp(firebaseConfig, 'clonear');
  };

  /**
   * Sets the user object.
   * @param user Firebase user response object
   */
  public setCurrentUser = (user: firebase.auth.Auth) => {
    this.user = user;
  };

  /**
   * Anonymous signin, so we could connect client to firebase
   */
  public signInAnonymously = ():
    | Promise<firebase.auth.UserCredential>
    | undefined => this.app?.auth().signInAnonymously() || undefined;

  /**
   * Logout from firebase
   * @param cb Callback executed when succesfully logged out
   */
  public signOut = (cb: () => void) => {
    this.app
      ?.auth()
      .signOut()
      .then(() => {
        if (cb) cb();
        return true;
      })
      .catch((err) => err);
  };
}
