import { createRxDatabase, addRxPlugin } from 'rxdb';
import { RxDBKeyCompressionPlugin } from 'rxdb/plugins/key-compression';
import { RxDBValidatePlugin } from 'rxdb/plugins/validate';
import { RxDBDevModePlugin } from 'rxdb/plugins/dev-mode';
import PouchdbAdapter from 'pouchdb-adapter-idb';
import {
  CloneARDatabase,
  CloneARDatabaseCollections,
  DBSelector,
} from '../types/db/database.types';
import DBConst from '../constants/db.constants';
import { ScheduleType } from '../types/db/schedule.types';

import { DBSettings, DBSettingsProperties } from '../types/db/settings.types';
import { DBSettingsEnum } from '../constants/settings.db.constants';

const {DBCollectionEnum,dbScheduleSchema,dbSettingsSchema} = DBConst

export default class DBService {
  db: CloneARDatabase | undefined;

  /**
   * Initializes the DB Service
   */
  public init = async () => {
    addRxPlugin(RxDBValidatePlugin);
    addRxPlugin(RxDBKeyCompressionPlugin);
    addRxPlugin(RxDBDevModePlugin);
    addRxPlugin(PouchdbAdapter);
    this.db = await createRxDatabase<CloneARDatabaseCollections>({
      name: 'etimetestv1',
      adapter: 'idb',
      multiInstance: true,
    });

    await this.db.collection({
      name: DBCollectionEnum.DB_SCHEDULES,
      schema: dbScheduleSchema,
    });
    await this.db.collection({
      name: DBCollectionEnum.DB_SETTINGS,
      schema: dbSettingsSchema,
    });
    return true;
  };

  /**
   * Store schedule in the DB
   * @param schedule Schedule Object according to ScheduleType
   */
  public storeSchedule = async (schedule: ScheduleType) => {
    const stored = await this.db?.[DBCollectionEnum.DB_SCHEDULES]
      .atomicUpsert(schedule)
      // .then((fin) => console.log('fin', fin))
      .catch((err) => console.log('err storing image', err));
    return stored;
  };

  /**
   *  Looks for a document by name or id
   * @param searchTerm search term
   * @param dbSelector either name or id
   * @param dbCollection Collection to search txt|img
   */
  public findScheduleByNameOrID = async (
    searchTerm: string,
    dbSelector: DBSelector,
  ) => {
    const regexp = new RegExp(`.*${searchTerm}.*`, 'i');
    const selector =
      dbSelector === 'id'
        ? { id: { $eq: searchTerm } }
        : { name: { $regex: regexp } };
    const found = await this.db?.[DBCollectionEnum.DB_SCHEDULES]
      ?.find({ selector })
      .exec();
    return found;
  };

  /**
   * Gets all schedules from the db
   * returns an observable
   */
  public getAllSchedules = () => this.db?.[DBCollectionEnum.DB_SCHEDULES].find().$;


  /**
   *  Looks for a document by name or id to delete
   * @param searchTerm search term
   * @param dbSelector either name or id
   */
  public deleteBySrcOrID = async (
    searchTerm: string,
    dbSelector: DBSelector,
  ) => {
    const selector =
      dbSelector === 'id'
        ? { id: { $eq: searchTerm } }
        : { name: { $regex: `.*${searchTerm}.*` } };
    const deleted = await this.db?.[DBCollectionEnum.DB_SCHEDULES]
      ?.find({ selector })
      .remove();
    return deleted;
  };

  /**
   * Removes all items in a Schedule collection
   */
  public removeAllItems = async () => {
    const deleted = await this.db?.[DBCollectionEnum.DB_SCHEDULES]?.find().remove();
    return deleted;
  };

  /**
   * Store setting in the DB
   * @param settings Text Object according to type DBSettings
   */
  public storeSetting = async (setting: DBSettings) => {
    setting.id = DBSettingsEnum.DB_SETTINGS_ID;
    const stored = await this.db?.[DBCollectionEnum.DB_SETTINGS];
    await stored
      ?.atomicUpsert(setting)
      .catch((err) => console.log('err storing text', err));
    return stored;
  };

  /**
   * Update setting in the DB
   * @param settings Text Object according to type DBSettings
   */
  public updateSetting = async (key: DBSettingsProperties, val: any) => {
    const stored = await this.db?.[DBCollectionEnum.DB_SETTINGS]
      .findOne()
      .exec();
    const r = await stored?.atomicSet(key, val);
    return r;
  };

  /**
   * Gets settings from the db
   * return an Promise
   */
  public getSettings = () =>
    this.db?.[DBCollectionEnum.DB_SETTINGS].findOne().exec();

  /**
   * Gets settings from the db
   * return an observable
   */
  public getSettingsObserve = () =>
    this.db?.[DBCollectionEnum.DB_SETTINGS].findOne().$;

  /**
   * Removes all items in a collection
   * either text or image in the db
   */
  public removeAllSettings = async () => {
    const deleted = await this.db?.[DBCollectionEnum.DB_SETTINGS]
      ?.find()
      .remove();
    return deleted;
  };
}
