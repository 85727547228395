/* eslint-disable react/prop-types */
import React, { ReactNode, useState, useEffect } from 'react';
import _ from 'lodash';
import {
  db,
  DBContext,
  fire,
  FireContext,
  rtc,
  RtcContext,
  SettingsContext,
  initContextManager,
} from '../contexts/manager.context';
import Loader from '../components/loader';
import { IPC_TO_SCREEN_SUB } from '../services/event.service';
import { DBSettings } from '../types/db/settings.types';
import { RTCLinkFormat } from '../types/event.types';
import { RTCEvents } from '../constants/events.constants';

interface ContextManagerProps {
  children: ReactNode;
}

const ContextManager = ({ children }: ContextManagerProps) => {
  const [settings, setSettings] = useState<DBSettings>();
  const [links, setLinks] = useState<RTCLinkFormat[]>([]);

  useEffect(() => {
    // Intialize app, context and services
    initContextManager((appSettings: DBSettings) => {
      console.log('got setting in INIT CTX', appSettings);
      setSettings(appSettings);
    });
  }, []);

  useEffect(() => {
    db.getSettingsObserve()?.subscribe((s) => {
      if (!s) return;
      if (!_.isEqual(s.toJSON(), settings)) setSettings(s.toJSON());
    });
  });

  useEffect(() => {
    // Subscribe to ipc response with new screen size
    IPC_TO_SCREEN_SUB.subscribe((data) => {
      if (!data) return;
      const { request, payload } = data;
      if (request === RTCEvents.RTC_CLIENT_CONNECTED) {
        const link: RTCLinkFormat = payload;
        const oldLinks = links;
        let newLinks = _.remove(oldLinks, { from: link.from });
        newLinks.push(link);
        newLinks = _.orderBy(newLinks, 'state', ['desc']);
        setLinks(newLinks);
      }
    });
  }, []);

  return settings ? (

    <SettingsContext.Provider value={{ settings, links }}>
      <FireContext.Provider value={fire}>
        <RtcContext.Provider value={rtc}>
          <DBContext.Provider value={db}>{children}</DBContext.Provider>
        </RtcContext.Provider>
      </FireContext.Provider>
    </SettingsContext.Provider>
  ): <Loader.CubesLoaderSmall />;
};

export default ContextManager;
