import 'firebase/database';
import md5 from 'md5';
import _ from 'lodash';
import { AR_TO_RTC_SUB } from './event.service';
import { RTCEvents } from '../constants/events.constants';
import {
  DataMsgFormat,
  RTCLinkFormat,
  FirebaseMsgFormat,
  RTCReceiveImage,
  ChunkedScreenShotPayload,
} from '../types/event.types';

const MAXIMUM_MESSAGE_SIZE = 65535;

export default class RtcService {
  // events: EventService;

  pc: RTCPeerConnection | undefined;

  sendChannel: RTCDataChannel | undefined;

  receiveChannel: RTCDataChannel | undefined;

  link: RTCLinkFormat;

  deviceName: string | undefined;

  userLinkID: string | undefined;

  fireapp: firebase.app.App | undefined;

  db: firebase.database.Reference | undefined;

  dbLink: firebase.database.Reference | undefined;

  receivedImage: string | undefined;

  chunkedPayload: Array<string> | undefined;

  constructor(fireapp: firebase.app.App | undefined) {
    this.fireapp = fireapp;
    this.deviceName = '';
    this.link = { from: '', to: '', name: '', nameFrom: '', state: false };

    this.receivedImage = '';
    this.chunkedPayload = [];
    this.init();
  }

  /**
   * Initializes the rtc service
   */
  private init = async () => {
    this.handleCreatePC();
    AR_TO_RTC_SUB.subscribe((msg) => {
      if (!msg) return;
      const { request, payload } = msg;
      switch (request) {
        case RTCEvents.RTC_SCREENSHOT:
          this.sendMessage(RTCEvents.RTC_SCREENSHOT, payload);
          break;
        case RTCEvents.RTC_SCREENSIZE:
          this.sendMessage(RTCEvents.RTC_SCREENSIZE, payload);
          break;
        case RTCEvents.RTC_DISCONNECT_CLIENT:
          this.handleDisconnect();
          break;
          break;
        default:
          break;
      }
    });
  };

  /**
   * Sets the user db ref by hashing uid
   * @param userID a firebase user id
   */
  public setUserLinkID = async (userID: string) => {
    this.userLinkID = await md5(userID);
    this.db = this.fireapp?.database().ref(`users/${this.userLinkID}`);
    this.setUpFireEvents();
    return this.userLinkID;
  };

  /**
   * Sets up the event handler for users db
   */
  setUpFireEvents = () => this.db?.on('child_added', this.handleRTC);

  /**
   * Handles the incoming payload event from db
   * Switch handels diffrent RTC msgType
   * @param data Incoming payload from db
   */
  private handleRTC = (data: firebase.database.DataSnapshot) => {
    const { msgType, payload, link }: FirebaseMsgFormat = data.val();
    const payloadParsed = JSON.parse(payload);
    this.link = link;
    this.link.name = link.nameFrom;
    if (this.pc) {
      switch (msgType) {
        case 'offer':
          this.pc
            .setRemoteDescription(new RTCSessionDescription(payloadParsed))
            .then(() => this.handleCreateAnswer())
            .catch(this.handleSetDescriptionError);

          break;
        case 'answer':
          this.pc
            .setRemoteDescription(new RTCSessionDescription(payloadParsed))
            .catch(this.handleSetDescriptionError);
          break;
        case 'ice':
          this.pc
            .addIceCandidate(new RTCIceCandidate(payloadParsed))
            .catch(this.handleAddCandidateError);
          break;
        default:
          break;
      }
    }
  };

  /**
   * Called when disconnected
   * Handles clean up
   */
  private handleDisconnect = _.debounce(
    () => {
      this.link.state = false;
      // toIpc({ request: RTCEvents.RTC_CLIENT_CONNECTED, payload: this.link });
      this.handleCreatePC();
    },
    100,
    { leading: true }
  );

  /**
   * Called when connected
   * Handles AR setup
   */
  private handleConnected = _.debounce(
    () => {
      console.log('this.recive channel', this.receiveChannel?.readyState);
      // if (this.receiveChannel?.readyState === 'closed')
      //   return this.handleDisconnect();
      this.link.state = true;
      // toIpc({ request: RTCEvents.RTC_CLIENT_CONNECTED, payload: this.link });
    },
    100,
    { leading: true }
  );

  /**
   * Sets up the RTC PeerConnection
   */
  private handleCreatePC = () => {
    const pcConfig: RTCConfiguration = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
    };
    this.pc = new RTCPeerConnection(pcConfig);
    this.sendChannel = this.pc.createDataChannel('sendChannel', {
      ordered: true, // guarantee order
      maxPacketLifeTime: 3000, // in milliseconds
    });
    this.sendChannel.onopen = this.handleSendChannelStatusChange;
    this.sendChannel.onclose = this.handleSendChannelStatusChange;
    this.pc.ondatachannel = this.receiveChannelCallback;

    this.pc.onicecandidate = (e) => {
      if (e.candidate) {
        this.sendToPeerDb('ice', JSON.stringify(e.candidate));
      }
    };

    this.pc.onconnectionstatechange = () => {
      // send the candidates to the remote peer
      // Check if we are connected
      if (this.pc?.connectionState) {
        switch (this.pc.connectionState) {
          // connection is established, do nothing but show in ui
          case 'connected':
            console.log('CONNECTED');
            this.handleConnected();
            break;
          // connection was closed, do nothing but show in ui
          // maybe user wants to connect to another party
          case 'closed':
            console.log('CLOSED');
            // this.handleDisconnect();
            break;
          // connection was disconnected, do nothing but show in ui
          // maybe user wants to connect to another party or reconnect, promt user?
          case 'disconnected':
            console.log('DISCONNECTED');
            this.handleDisconnect();
            break;
          // connection failed, here we should try to reconnect
          // maybe old stored connection has outdated ice
          case 'failed':
            console.log('FAILED');
            // this.handleDisconnect();
            break;
          // we have not connected yet,
          case 'new':
            console.log('NEW');
            // we are connecting, maybe show ui changes,
            break;
          case 'connecting':
            console.log('CONNECTING');
            break;
          default:
            break;
        }
      }
    };
  };

  /**
   * Sends payload to linked peer db the removes msg
   * @param msgType FirebaseMsgType offer|answer|ice
   * @param payload Stringified payload with sdp
   */
  private sendToPeerDb = async (msgType: string, payload: string) =>
    (await this.dbLink?.push({ link: this.link, msgType, payload }))?.remove();

  /**
   * Creates an RTC offer
   * @param linkTo Peer id to send the payload to
   */
  private handleCreateOffer = (linkTo: string) => {
    if (!this.pc || !this.userLinkID) return;
    this.link = {
      from: this.userLinkID,
      to: linkTo,
      name: this.link.name,
      nameFrom: this.deviceName,
    };
    this.pc
      .createOffer()
      .then((offer) => {
        if (!this.pc) throw new Error('PC was not found');
        this.pc.setLocalDescription(offer);
        this.sendToPeerDb('offer', JSON.stringify(offer));
        return undefined;
      })
      .catch(this.handleCreateDescriptionError);
  };

  /**
   * Creates an RTC answer
   */
  private handleCreateAnswer = () => {
    if (!this.pc || !this.userLinkID) return;
    this.link = {
      from: this.userLinkID,
      to: this.link.from,
      name: this.link.name,
      nameFrom: this.deviceName,
    };
    this.dbLink = this.fireapp?.database().ref(`users/${this.link.to}`);
    this.pc
      .createAnswer()
      .then((answer) => {
        if (!this.pc) throw new Error('PC was not found');
        this.pc.setLocalDescription(answer);
        this.sendToPeerDb('answer', JSON.stringify(answer));
        return undefined;
      })
      .catch(this.handleCreateDescriptionError);
  };

  /**
   * Handles the status change on sendChannel
   */
  private handleSendChannelStatusChange = () => {
    if (this.sendChannel) {
      const rtcState = this.sendChannel.readyState;
      if (rtcState === 'open') {
        console.log(' SendChannelStatus change active and OPEN');
      } else {
        // set disable ui state here
        console.log(' SendChannelStatus change active and CLOSED');
        // this.handleDisconnect();
      }
    }
  };

  /**
   * Handles Errors for RTC create SDP
   * @param error RTC error event
   */
  private handleCreateDescriptionError = (error: Error) => {
    console.log('Unable to create an offer: ', error.toString());
  };

  /**
   * Handles Errors for RTC Set Description
   * @param error RTC Error event
   */
  private handleSetDescriptionError = (error: Error) => {
    console.log('Unable to set an offer: ', error.toString());
  };

  /**
   * Handles Errors for Ice candidates
   * @param error RTC Error event
   */
  private handleAddCandidateError = (error: Error) => {
    console.log('addICECandidate failed!', error);
  };

  /**
   * Chunks the BASE64 string into multiple elements
   * TODO add hash verification
   * @param str String is part of BASE64 to be sent
   * @param length Is determined by the CONST MAXIMUM_MESSAGE_SIZE
   */
  private chunkString = (str: string, length: number) => {
    return str.match(new RegExp(`.{1,${length}}`, 'g')) || undefined;
  };

  /**
   * Handles chunking, hashing and sending a a screenshot image along with the screen's dimensions
   * @param request RTCEvent of type RTCScreenShot
   * @param screenShotPayload String of base64
   */
  private handleImageSender = (request: RTCEvents, payload: string) => {

    const screenShotPayload = {
      encodedImage: payload
    };
    this.chunkedPayload = this.chunkString(
      screenShotPayload.encodedImage,
      MAXIMUM_MESSAGE_SIZE
    );
    if (!this.chunkedPayload)
      return console.error(
        'Could not send screenshot.\nChunkedImage was:',
        this.chunkedPayload,
        '\nSendChannel was:',
        this.sendChannel
      );
    return this.chunkedPayload.forEach((chunk: string, ix: number) => {
      const chunkedScreenShotPayload: ChunkedScreenShotPayload = {
        chunk,
        expectedChunks: this.chunkedPayload?.length || 0,
        currentChunk: ix + 1,
      };
      if (ix + 1 === this.chunkedPayload?.length) {
        chunkedScreenShotPayload.checksum = md5(screenShotPayload.encodedImage);
      }
      console.log('request', request);
      console.log('payload', chunkedScreenShotPayload);
      this.sendChannel?.send(
        JSON.stringify({ request, payload: chunkedScreenShotPayload })
      );
    });
  };

  /**
   * Sends payload to connected rtc peer
   * @param request Must be of type RTCEvents
   * @param payload Any payload to be sent ie Image etc
   * @param cb Any callbacks after payload is sent
   */
  private sendMessage = (request: RTCEvents, payload: any, cb?: () => void) => {
    if (this.sendChannel?.readyState === 'open') {
      if (request === RTCEvents.RTC_SCREENSHOT) {
        this.handleImageSender(request, payload);
      } else {
        this.sendChannel.send(
          JSON.stringify({
            request,
            payload,
          })
        );
      }
      if (cb) cb();
    }
  };

  /**
   * Called when the connection opens and the data
   * channel is ready to be connected to the remote
   */
  private receiveChannelCallback = (event: RTCDataChannelEvent) => {
    this.receiveChannel = event.channel;
    this.receiveChannel.onmessage = this.handleReceiveMessage;
    this.receiveChannel.onopen = this.handleReceiveChannelStatusChange;
    this.receiveChannel.onclose = this.handleReceiveChannelStatusChange;
  };

  /**
   * Handles events on the receive channel
   * @param event RTC Channel Event
   */
  private handleReceiveChannelStatusChange = (_event: Event) => {
    if (this.receiveChannel) {
      if (this.receiveChannel.readyState === 'open') {
        // channel is open lets setup AR screen and send screen size
        // this.handleConnected()
        console.log(
          'OPEN',
          'his.receiveChannel.readyState',
          this.receiveChannel.readyState
        );
      }
      if (this.receiveChannel.readyState === 'closed') {
        console.log(
          'CLOSED',
          'his.receiveChannel.readyState',
          this.receiveChannel.readyState
        );
        // this.handleDisconnect();
      }
    }
  };

  /**
   * Handles the incomming image payload
   * @param param0 Incoming image payload
   */
  private handleReceivedImage = ({ request, payload }: DataMsgFormat): void => {
    const imgPayload: RTCReceiveImage = payload;
    this.receivedImage += imgPayload.chunk;

    if (
      imgPayload.expectedChunks === imgPayload.currentChunk &&
      this.receivedImage
    ) {
      if (imgPayload.checksum !== md5(this.receivedImage)) {
        console.warn('(RTC) received image:', this.receivedImage);
        this.receivedImage = '';
        return console.warn('(RTC) Did not receive segmented image correctly!');
      }
      console.log({ request, payload: `data:image/png;base64,${this.receivedImage}`, })
      // toIpc({ request: RTCEvents.START_AR_SCREEN, payload: null });
      // toIpc(
      //   {
      //     request,
      //     payload: `data:image/png;base64,${this.receivedImage}`,
      //   },
      //   true
      // );
      this.receivedImage = '';
      return undefined;
    }
    return undefined;
  };

  /**
   * Handles the incomning RTC request and payloads
   * @param param0 RTC Message Event
   */
  private handleReceiveMessage = ({ data: _data }: MessageEvent) => {
    try {
      const { request, payload }: DataMsgFormat = JSON.parse(_data);
      switch (request) {
        case RTCEvents.RTC_IMAGE:
          this.handleReceivedImage({ request, payload });
          break;
        case RTCEvents.RTC_TEXT:
          console.log({ request, payload })
          // toIpc({ request: RTCEvents.START_AR_SCREEN, payload: null });
          // toIpc({ request, payload }, true);
          break;
        case RTCEvents.RTC_CLEAR_IMAGE || RTCEvents.RTC_CLEAR_TEXT:
          console.log({ request, payload })
          // toIpc({ request: RTCEvents.END_AR_SCREEN, payload: null });
          break;
        default:
          // toIpc({ request, payload }, true);
          console.log({ request, payload })
          break;
      }
    } catch (error) {
      console.error('Error occurd:', error);
    }
  };
}
