/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react';
import style from './index.less';

const TIMER = 150; // Milliseconds between moving the next block
const TRANSITION = 0.5; // Seconds to actually move one block
const DEF_SIZE = 60; // Pixels height/width
const GUTTER = 5; // Spacing in percentage between tiles

interface CubesProps {
  data: {
    size: number;
  };
}

interface CubesState {
  positions: { [key: number]: string };
  stateNumber: number;
}

const initialState: CubesState = {
  positions: {
    1: 'alpha',
    2: 'bravo',
    3: 'charlie',
    4: 'null',
    5: 'delta',
    6: 'echo',
    7: 'foxtrot',
  },
  stateNumber: 0,
};

class Cubes extends React.Component<CubesProps, CubesState> {
  timer: number | undefined;

  constructor(props: CubesProps) {
    super(props);
    this.state = initialState;
    this.setNextState = this.setNextState.bind(this);
  }

  componentDidMount() {
    this.setTimer(TIMER);
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  setTimer(time: number) {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.timer = setInterval(this.setNextState, time);
  }

  setNextState() {
    const { positions } = this.state;
    const currentPositions = positions;
    const emptyIndex = this.positionForTile('null') || '';
    const indexToMove:"null" | 7 | 6 | 5 | 4 | 3 | 2 | 1 = this.tileIndexToMove();
    const newPositions = {
      ...currentPositions,
      [indexToMove]: 'null',
      [emptyIndex]: currentPositions[1],
    };

    const { stateNumber } = this.state;
    const currentState = stateNumber;
    const nextState = currentState === 7 ? 0 : currentState + 1;

    this.setState({ stateNumber: nextState, positions: newPositions });
  }

  tileIndexToMove = () => {
    const { stateNumber } = this.state;
    switch (stateNumber) {
      case 0:
        return 7;
      case 1:
        return 6;
      case 2:
        return 5;
      case 3:
        return 4;
      case 4:
        return 3;
      case 5:
        return 2;
      case 6:
        return 1;
      case 7:
        return 4;
      default:
        return 'null';
    }
    // return undefined;
  };

  clipPathForPosition = (pos: string) => {
    const position = parseInt(pos, 10);
    const SIZE = (100 - 2 * GUTTER) / 3;
    const VAR0 = '0% ';
    const VAR1 = `${SIZE + GUTTER}% `;
    const VAR2 = `${2 * SIZE + 2 * GUTTER}% `;
    switch (position) {
      case 1:
        return `inset(${VAR1}${VAR2}${VAR1}${VAR0} round 5%)`;
      case 2:
        return `inset(${VAR0}${VAR2}${VAR2}${VAR0} round 5%)`;
      case 3:
        return `inset(${VAR0}${VAR1}${VAR2}${VAR1} round 5%)`;
      case 4:
        return `inset(${VAR1}${VAR1}${VAR1}${VAR1} round 5%)`;
      case 5:
        return `inset(${VAR2}${VAR1}${VAR0}${VAR1} round 5%)`;
      case 6:
        return `inset(${VAR2}${VAR0}${VAR0}${VAR2} round 5%)`;
      case 7:
        return `inset(${VAR1}${VAR0}${VAR1}${VAR2} round 5%)`;
      default:
        return '';
    }
  };

  objectMap = (obj: any, fn: any) =>
    Object.fromEntries(
      Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
    );

  // eslint-disable-next-line consistent-return
  positionForTile = (radioCommand: string ) => {
    const { positions } = this.state;
    for (const position in positions) {
      const tile = positions[position];
      if (tile === radioCommand) {
        return position;
      }
    }
  };

  renderTiles = () => {
    return ['alpha', 'bravo', 'charlie', 'delta', 'echo', 'foxtrot'].map(
      (radioCommand) => {
        const pos = this.positionForTile(radioCommand);
        const styles = {
          transition: `${TRANSITION}s cubic-bezier(0.86, 0, 0.07, 1)`,
          WebkitClipPath: pos ? this.clipPathForPosition(pos) : '',
        };
        return (
          <div
            key={`rect_${radioCommand}`}
            style={styles}
            // className={`rect ${radioCommand}`}
            className={`${style.rect} ${radioCommand}`}
          />
        );
      }
    );
  };

  render() {
    // style, center,
    const {
      data: { size },
    } = this.props;
    const styles = {
      width: `${DEF_SIZE}px`,
      height: `${DEF_SIZE}px`,
      ...style,
    };

    if (size) {
      styles.width = `${size}px`;
      styles.height = `${size}px`;
    }

    // const className = 'sw_loader__wrapper';
    const className = style.sw_loader__wrapper;
    // if (center) {
    //   className += ' swloader__wrapper--center';
    // }

    return (
      <div style={styles} className={className}>
        {/* <div className={'sw_loader__holder'}>{this.renderTiles()}</div> */}
        <div className={style.sw_loader__holder}>{this.renderTiles()}</div>
      </div>
    );
  }
}

export default Cubes;
